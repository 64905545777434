import React from "react";
import _ from "lodash";
import { Trans } from "next-i18next";
import { Spacer } from "@nextui-org/react";
import Lottie from "@/components/lottie";

import { cn } from "@/components/cn";
import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import { SectionSpacing } from "@/utils";

interface InputItem {
  animation: Record<string, any>;
  height?: number; // Make height optional
}

interface TranslatedItem {
  title: string;
  description: string;
  items?: string[];
}

interface UseCasesProps {
  t: (key: string, options?: { returnObjects: boolean }) => any;
  items: InputItem[];
  className?: string;
}

function Usecases({ t, items, className }: UseCasesProps) {
  const translated = t("items") as TranslatedItem[];

  const combinedItems = _.map(translated, (row, index) => ({
    ...row,
    animation: items[index]?.animation,
    height: items[index]?.height ?? 280, // Set default height to 280 if not provided
  }));

  return (
    <section className={cn("container text-center", SectionSpacing, className)}>
      <Title as="h2" variant="large" className=" mb-0 lg:mb-10">
        <Trans
          i18nKey="title"
          t={t}
          components={[
            <span
              key="title-hightlight"
              className="text-transparent"
              style={{
                background: "linear-gradient(89deg, #802CFD 72.9%, #F684FF 99.06%)",
                backgroundClip: "text",
              }}
            >
              Automation
            </span>,
          ]}
        />
      </Title>
      <Spacer y={10} />
      <div className="py-5 flex flex-col gap-20 sm:gap-0">
        {/* Animation position: on the right at index even number, and left at index odd number */}
        {_.map(combinedItems, (item, index) => (
          <div
            className={cn("flex flex-wrap sm:flex-nowrap", index % 2 !== 0 ? "flex-row-reverse" : "")}
            key={item.title}
          >
            <div className="w-full sm:w-1/2 flex justify-center items-center overflow-hidden">
              <Lottie style={{ height: item.height }} animationData={item.animation} />
            </div>
            <div className="flex flex-col justify-center sm:text-left px-12 w-full sm:w-1/2">
              <Title as="h3" variant="small">
                {item.title}
              </Title>
              <Spacer y={5} />
              <Body as="p" variant="large" className="opacity-60">
                {item.description}
              </Body>
              {!_.isEmpty(item.items) && (
                <ul className="list-disc list-inside opacity-60">
                  {_.map(item.items, (subItem) => (
                    <li key={subItem}>{subItem}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Usecases;
